import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import UpcomingEvents from "../components/upcomingevents"

const Events = () => (

  <Layout>
    <SEO title="Events" />
  
    <PostHero
        title="Events"
        description="Upcoming virtual classes and bars"
        image={require('../images/pexels-andrea-piacquadio-3769137.jpg')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="topline-statement">
                <p>Our content exhibited in compelling and engaging programming.</p>        
            </div>


            <div className="hub-section-title center">
                <h2>Upcoming Events</h2>
            </div>
            <div className="hub-cards-section">
                <UpcomingEvents />
            </div>

            {/*<div className="hub-section-title center">
                <h2>Recent Events</h2>
            </div>
            <div className="hub-cards-section">
                <PastEvents />
            </div>*/}

         </div>

      </div>

  </Layout>
)

export default Events